<template>
  <b-card>
      <!-- Form -->
      <b-form
        class="p-2"
      >
      <b-row>
         <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-media no-body class="mt-3">
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  :src="rowData.data.logo"
                />
              </b-link>
            </b-media-aside>

            <b-media-body class="mt-75 ml-75 mb-3">
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.refInputElLogo.$el.click()"
                >
                  Pilih Logo
                </b-button>
                <b-form-file
                  ref="refInputElLogo"
                  v-model="rowData.data.file"
                  accept=".jpg, .png, .gif"
                  :hidden="true"
                  plain
                  @input="onFileChangeLogo(rowData.data.file)"
                />
              </div>
              <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
        <b-row>
          <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Nama</label>
           <b-form-input
              id="nama"
              autofocus
              trim
              placeholder="Masukkan Nama Instansi"
              v-model="rowData.data.nama"
            />
            <small class="mt-1" style="color: red;" v-if="error.nama">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Negara</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="negaraOptions"
            @input="(val) => $emit('update:uptFilter', val)"
            v-model="rowData.data.negara_id"
          />
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Provinsi</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('emitLoadKota', val)"
            v-model="rowData.data.provinsi_id"
            :options="provinsiOptions"
          />
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Kota/Kabupaten</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="rowData.data.kota_id"
            :options="kotaOptions"
          />
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Email</label>
            <b-form-input
              id="email"
              trim
              placeholder=""
              type="email"
              v-model="rowData.data.email"
            />
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Telepon</label>
          <b-form-input
              id="telepon"
              trim
              placeholder=""
              v-model="rowData.data.telepon"
            />
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Alamat</label>
           <b-form-textarea
              id="alamat"
              v-model="rowData.data.alamat"
              placeholder="Enter something..."
              rows="3"
            ></b-form-textarea>
            <small class="mt-1" style="color: red;" v-if="error.alamat">{{error.message}}</small>
        </b-col>
      </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="updateData"
          >
            Simpan
          </b-button>
        </div>

      </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg,
  BFormSpinbutton, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { required, alphaNum, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import useList from '@/connection-api/instansi/list'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import {
  uploadFileLogo,
  downloadLogo,
} from '@/connection-api/instansi'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
      },
      required,
      alphaNum,
      email,
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          altFormat: 'M j, Y',
          altInput: true,
          dateFormat: 'Y-m-d',
        },
      },
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
  },
  async mounted() {
    if(this.rowData.data.logo != null)
    {
      const respLogo = await downloadLogo(this.rowData.data.logo)
      const fileURLLogo = window.URL.createObjectURL(new Blob([respLogo.data]))
      this.rowData.data.logo = fileURLLogo
    }
   
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserStatusVariant } = useList()
    const blankUserData = {
    }
    // alert(this.userData.nama)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const onSubmit = () => {
      store.dispatch('instansi/updateData', this.rowData.data)
        .then(response => {
        })
        .catch(error => {

        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    onFileChangeLogo(val) {
      if(val !== null)
      {
        this.rowData.data.logo = URL.createObjectURL(val)
      }
    },
    async uploadLogo(id)
    {
      if(this.rowData.data.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.rowData.data.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async updateData() {
      store.dispatch('instansi/updateData', this.rowData.data)
        .then(response => {
          this.uploadLogo(this.rowData.data.id)
          this.makeToast('success', 'Simpan Data Jenis Dokumen Berhasil Dilakukan')
        })
        .catch(error => {
          // console.log(error)
        })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate(value, format) {
      return moment(String(value)).format(format)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        // title: `Variant ${variant || 'default'}`,
        title: 'Biodata',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
}
</script>

<style>

</style>
